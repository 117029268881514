<!-- Hero Start -->
<section class="vh-100 d-flex align-items-center"
  style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
        <div class="title-heading mt-5 pt-4">
          <h2 class="heading">Let's talk about your portfolio</h2>       
 

          <div class="d-flex contact-detail align-items-start mt-4">
            <div class="icon">
              <i-feather name="user" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Ebenezer Anderson</h5>
              <p class="text-muted">Chief Executive officer</p>
            </div>
          </div>
          <div class="d-flex contact-detail align-items-start">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Email</h5>
              <a href="mailto:contact@example.com" class="text-primary">aaceconsultancyind@gmail.com</a>
            </div>
          </div>

        
          <div class="d-flex contact-detail align-items-start mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Address</h5>
              <p class="text-muted"><span class="text-primary fw-bold">ACE CONSULTANTS AND ENGINEERS</span> <br> 13A, First Floor, Surya 3rd street, kamatchipuram, Ondipudhur, Coimbatore - 641016, INDIA <br>
                <a href="www.aceconsengg.org" class="text-primary">www.aceconsengg.org</a>
              </p>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-start">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Phone</h5>
              <a href="tel:+919361895123" class="text-primary">+91 9361895123</a> <br>
              <a href="tel:+917010379835" class="text-primary">+91 7010379835</a>
            </div>
          </div>

      

          <!-- <ul class="list-unstyled social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
              </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul> -->
          <!--end icon-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->