<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> About us </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">ACE</a></li>

                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/company/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
          <!-- <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="play-btn">
              <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
            </a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div> -->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Our Story</h4>
          <p class="text-muted">Start working with <span class="text-primary fw-bold">ACE</span> ACE Consultants and
            Engineers, a new startup company founded by two young and
            energetic siblings specialized in engineering and financial studies and was started with an
            aim of serving the Indian industries by means of mastering the consultancy, Owner’s
            Engineering solutions, supply chain solutions for engineering industry, EPC services, Crane
            rentals, Logistics and financial consultancy. Our industrial focus is towards the industries
            like construction, ESP, Oil &amp; Gas, Mining, Chemical, petroleum, Coal and Renewable energy
            on the Engineering side. On the financial side, the focus is on the Asset managements,
            investments, fund management, insurances, non banking financial services and
            consultancy.</p>
          <!-- <a href="javascript:void(0)" class="btn btn-primary mt-3">Buy Now <i class="uil uil-angle-right-b"></i></a> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">VISION AND MISSION</h4>
          <p class="text-muted para-desc mx-auto mb-0"> <span class="text-primary fw-bold">The vision is NOTHING IS
              POSSIBLE WITHOUT GOD</span> </p>
          <p>And the Mission is to provide zero tolerance service, global quality service, safe service and
            satisfactory service to the Indian Industry by means of automation, upgrading, excellence
            and team work.</p>
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
</section>

<!-- Team Start -->
<section class="section ">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Greatest Minds</h4>
          <p class="text-muted para-desc mx-auto mb-0"> <span class="text-primary fw-bold">ACE</span> is founded and
            managed by a team with a mix of academic and career expertise as
            listed below:</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
        <div class="card team text-center bg-transparent border-0">
          <div class="card-body p-0">
            <div class="position-relative">
              <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
              <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                <li class="list-inline-item" *ngFor="let item of data.list">
                  <a [href]="item.url" class="rounded ms-1" target="_blank">
                    <i-feather name="{{item.icon}}" class="fea icon-sm fea-social"></i-feather>
                  </a>
                </li>
              </ul>
              <!--end icon-->
            </div>
            <div class="content pt-3 pb-3">
              <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
              <small class="designation text-muted">{{data.designation}}</small>
              <small class="designation text-muted">{{data.description}}</small>
            </div>
          </div>
        </div>
      </div>

      <!--end row-->
    </div>
    <!--end container-->


    <!--end container-->

    <!--end section-->
    <!-- Team End -->

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->