<!-- TAGLINE START-->
<div class="tagline bg-light" *ngIf="sliderTopbar == true">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0"><b>EOG</b> $ 55.88 <span class="text-success">+$ 4.62 ( +9.01%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>CHKP</b> $ 120.03 <span class="text-danger">-$ 14.07 ( -10.49%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>FANG</b> $ 63.58 <span class="text-success">+$ 5.17 ( +8.84%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>M</b> $ 14.75 <span class="text-success">+$ 1.05 ( +7.66% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>PKI</b> $ 139.72 <span class="text-danger">-$ 11.41 ( -7.55%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ABMD</b> $ 326.23 <span class="text-danger">-$ 21.61 ( -6.21%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN</b> $ 37,471.47 <span class="text-danger">+$ 492.60 (
                +1.33% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>XRP</b> <span> $ 0.39</span><span class="text-muted"> UNCH</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>LITECOIN</b> <span> $ 148.67</span><span class="text-danger">-$
                5.58 ( -3.62% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN CASH</b> <span> $ 427.37</span><span
                class="text-danger">-$ 15.98 ( -3.60% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ETHEREUM</b> $ 1,647.87 <span class="text-danger">+$ 14.51 (
                +0.89% )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TAGLINE END-->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/ace/Ace Black.svg" class="l-dark logo-light-mode" height="40" alt="">
        <img src="assets/ace/Ace White.svg" class="logo-dark-mode" height="40" alt="">
      </a>

     

     

      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/ace/Ace Black.svg" class="l-dark" height="40" alt="">
        <img src="assets/ace/Ace White.svg" class="l-light" height="40" alt="">
      </a>
    </div>

    <div class="buy-button">
      <a routerLink="/contact-us" target="_blank">
          <span class="btn btn-primary d-none d-md-inline-block"><i class="mdi mdi-phone"></i> Contact Us</span>
          <span class="btn btn-icon btn-primary d-inline-flex align-items-center justify-content-center d-md-none ms-1"><i class="mdi mdi-phone"></i></span>
      </a>
  </div>
    <!--end login button-->

    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li><a routerLink="/about-us" class="nav-link-ref">About us</a></li>
        <li><a routerLink="/services" class="nav-link-ref">Services</a></li>
       
      </ul>
      <!--end navigation menu-->
      

    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->