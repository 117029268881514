import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface SocialLink {
  icon: string,
  url: string
}

interface Member {
  profile: string,
  list: SocialLink[],
  name: string,
  designation: string,
  description: string
};

@Component({
  selector: 'app-page-aboutus',
  templateUrl: './page-aboutus.component.html',
  styleUrls: ['./page-aboutus.component.css']
})

/**
 * Aboutus Component
 */
export class PageAboutusComponent implements OnInit {

  /**
   * Member Data
   */
  memberData: Member[] = [


    {
      profile: "assets/ace/ebe-dp.jpg",
      list: [
        {
          icon: "linkedin",
          url: "https://www.linkedin.com/in/ebenezer-anderson-30a1871b8"
        }
      ],
      name: "EBENEZER ANDERSON",
      designation: "CHIEF EXECUTIVE OFFICER",
      description: `An engineer by mind from the age of 10, he has completed his integrated Engineering
Bachelor degree in Mechanical Manufacturing from PSG Institute in India and in the
University of Hull in UK. And continued to completed his Masters in Renewable Energy from
the same University of Hull, UK. Adding industry internships in various companies inside and
outside India and also having few years of experience in engineering and Renewable energy
industry, he is focused towards the core services of the company`
    },
    {
      profile: "assets/ace/catherine-dp.jpg",
      list: [],
      name: "CATHRINE ANDERSON",
      designation: "MANAGING DIRECTOR",
      description: `With lot of experience close to 2 decades in the FMCG sector, she is assisting the
organization and looking after the administration of the organization. Her expertise is in
building organizations, man power management and a good communicator.`
    },
    {
      profile: "assets/ace/anderson-dp.jpg",
      list: [
        {
          icon: "linkedin",
          url: "https://www.linkedin.com/in/anderson-samuel-1006b734"
        }
      ],
      name: "ANDERSON SAMUEL",
      designation: "BOARD MEMBER AND ADVISOR",
      description: `An engineer by profession and carrying three decades of industrial experience, he is a Board
appointed member and also the leading advisor to this organization. Having travelled to 24
countries for work and business across continents and having handled various top
management positions, he adds value to the organization by his vast industrial expertise
and the business network created in the journey.`
    },
    {
      profile: "assets/ace/angelin-dp.jpg",
      list: [
        {
          icon: "linkedin",
          url: "https://www.linkedin.com/in/angelin-felcia-672818212/"
        }
      ],
      name: "ANGELIN FELCIA ANDERSON",
      designation: "MANAGING PARTNER",
      description: `Having an interest towards the financial studies, she has completed her Bachelor in
Commerce from a reputed institute and is pursuing CMA and CFA. She is a member of BFSI
and an associate member of TIE CHENNAI. She looks after the management of the financial
aspects of the company and also the finance related businesses of the organization.`
    },
  ];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
