<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading mt-4">
          <h1 class="heading mb-3">Our Services</h1>
          <p class="para-desc text-muted">Discover the range of services we offer to various industries including construction, engineering, financial solutions, and more.</p>
          <div class="mt-4 pt-2">
            <!-- <a routerLink="/page-services" class="btn btn-primary m-1">Explore Services</a> -->
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/illustrator/services.svg" alt="Services">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Services Start -->
<section class="section pt-0">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Construction, Installation, and Engineering Solutions</h4>
          <p class="text-muted para-desc mx-auto mb-0">Our comprehensive engineering solutions cater to various industries such as Civil & Construction, ESP, Oil & Gas, Coal & Mining, Chemical, Petroleum, and Renewable Energy.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-setting"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Engineering Consultancy</h5>
            <p class="text-muted">Expert consultancy services for any Engineering companies in the abive
              mentioned industrial sectors.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-hard-hat"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Owner’s Engineering</h5>
            <p class="text-muted">Provide onsite, offsite, online support of inspections, due diligence, warranty, post
              warranty inspections, maintenance supervisions, IRATA supervisors, etc</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-truck"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Tower Crane Supply and Service</h5>
            <p class="text-muted">Import and supply of Tower cranes for construction works for any industrial sector
              ranging from 4 ton to 25 tons. Also supply of all kind of spares for the cranes and
              service works. Used Tower crane supply. Rental of Tower cranes for long term
              projects.</p>
          </div>
        </div>
      </div>
      <!--end col-->
      
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-link"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Supply Chain Solutions</h5>
            <p class="text-muted">Expertise supply chain solutions like procurement of all kind of Engineering
              equipments like Electrical forklifts, Diesel forklifts, related spares, bearings, etc</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-building"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">EPC Services</h5>
            <p class="text-muted">Deputation of skilled Man power, Riggers, semi skilled man power, helpers for the
              construction, engineering, ESP, Oil &amp; Gas, Mining installation works across India.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-bolt"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Crane Rental</h5>
            <p class="text-muted">Short-term, long-term, and project-based rental of all kinds of crawler, mobile, and all-terrain cranes, ranging from small 15 tons to 800 tons
            </p>
          </div>
        </div>
      </div>
      <!--end col-->








      
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-box"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Logistics and Warehousing</h5>
            <p class="text-muted">Trailers, extended boom trailers, specialized trailers, pullers for lease, long term, short term rentals, movement of heavy equipments. Ware house and storage solutions for any materials on a turn key basis including movement, handling and storage.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Services End -->

<!-- Financial Solutions Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Financial Solutions</h4>
          <p class="text-muted para-desc mx-auto mb-0">On the financial front, we offer the below services to companies (mid and small), individuals
            and all kind of institutions</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-chart-line"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Asset Management</h5>
            <p class="text-muted">Provide services related to existing asset management and new asset investments
              and the complete management towards marginal increase in asset value</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-arrow-up"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Portfolio Investments</h5>
            <p class="text-muted">Service towards various investments in Equities, debt funds, loan bonds, etc
              and maintain the portfolios on behalf of the investors on AMC basis.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-dollar-sign"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Fund Management</h5>
            <p class="text-muted">Combining the above two, provide service of managing the complete assets,
              investments and related fund transactions and further investments.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature-primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
            <i class="uil uil-shield-check"></i>
          </div>
          <div class="content mt-3">
            <h5 class="title">Insurances</h5>
            <p class="text-muted">Various kind of insurances like risk insurances, liability insurance, business
              related insurances, Third party insurances, etc</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="features feature -primary text-center">
          <div class="icon text-center rounded-circle mx-auto">
          <i class="uil uil-building"></i>
          </div>
          <div class="content mt-3">
          <h5 class="title">Non-Banking Financial Services</h5>
          <p class="text-muted">Identifying suitable Private Equity and Venture capital investors for various small and
            mid cap businesses</p>
          </div>
          </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="features feature-primary text-center">
              <div class="icon text-center rounded-circle mx-auto">
                <i class="uil uil-money-bill"></i>
              </div>
              <div class="content mt-3">
                <h5 class="title">Financial Consultancy</h5>
                <p class="text-muted">Provide consultancy for organizations and individuals for all the above services or
                  any one or mixed service.</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Financial Solutions End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" ngxScrollTo="#home">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->
        

